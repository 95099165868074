exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-components-footer-jsx": () => import("./../../../src/pages/blog/components/Footer.jsx" /* webpackChunkName: "component---src-pages-blog-components-footer-jsx" */),
  "component---src-pages-blog-components-header-jsx": () => import("./../../../src/pages/blog/components/Header.jsx" /* webpackChunkName: "component---src-pages-blog-components-header-jsx" */),
  "component---src-pages-blog-memory-algorithmic-cognitive-enhancer-js": () => import("./../../../src/pages/blog/memory-algorithmic-cognitive-enhancer.js" /* webpackChunkName: "component---src-pages-blog-memory-algorithmic-cognitive-enhancer-js" */),
  "component---src-pages-blog-universal-service-adapter-model-lov-js": () => import("./../../../src/pages/blog/universal-service-adapter-model-lov.js" /* webpackChunkName: "component---src-pages-blog-universal-service-adapter-model-lov-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-training-google-web-search-engineer-math-index-js": () => import("./../../../src/pages/training/google-web-search-engineer-math/index.js" /* webpackChunkName: "component---src-pages-training-google-web-search-engineer-math-index-js" */),
  "component---src-pages-training-index-js": () => import("./../../../src/pages/training/index.js" /* webpackChunkName: "component---src-pages-training-index-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdown-template.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */)
}

